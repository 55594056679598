<template>
  <v-dialog :fullscreen="!$vuetify.breakpoint.mdAndUp" v-model="dialog" max-width="900">
    <v-card class="bs-shadow">
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-regular pa-2">
        History
        <v-btn icon class="px-2" @click="close_dialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-card-text class="pa-2">
        <v-data-table :headers="headers" :items="records" :loading="records_loading" loading-text="Loading, please wait"
          :items-per-page="5">

          <template v-slot:[`item.amount`]="{ item }">
            {{ money_format(parseFloat(item.amount)) }}
          </template>

          <template v-slot:[`item.requested_at`]="{ item }">
            {{ format_date(item.requested_at) }}
          </template>

          <template v-slot:[`item.settled_at`]="{ item }">
            {{ format_date(item.settled_at) }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip small :color="item.status == 'Pendig' ? '#ECEFF1' : item.status == 'Requested' ? '#FFF3E0' : '#E8F5E9'"
            :text-color="item.status == 'Pendig' ? '#78909C' : item.status == 'Requested' ? '#FFA726' : '#66BB6A'">
              <span class="font-weight-medium">
                {{ item.status }}
              </span>
            </v-chip>
          </template>
        
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import formatDate from "@/helpers/formatDate";
import responseGet from '@/helpers/api_request_get'

export default {
  data: () => ({
    records: [],
    records_loading: false,
    headers: [
      {
        text: 'Superior',
        align: 'start',
        value: 'superior',
        class: 'text-uppercase'
      },
      {
        text: 'Amount',
        align: 'right',
        value: 'amount',
        class: 'text-uppercase'
      },
      {
        text: 'Status',
        align: 'center',
        value: 'status',
        class: 'text-uppercase'
      },
      {
        text: 'Requested At',
        align: 'center',
        value: 'requested_at',
        class: 'text-uppercase'
      },
      {
        text: 'Settled At',
        align: 'center',
        value: 'settled_at',
        class: 'text-uppercase'
      }
    ],
  }),

  computed: {
    dialog: {
      get: function () {
        return true
      },

      set: function () {
        this.close_dialog()
      }
    }
  },

  mounted() {
    this.doGetCommissionRequestHistory()
  },

  methods: {
    async doGetCommissionRequestHistory() {
      this.records_loading = !this.records_loading

      const response = await responseGet('', 'commission/history')

      switch (response.status) {
        case 200: {
          this.records = response.data.records
        }
          break
        case 401: {
          this.$store.commit("resetStates", "", { root: true })
          this.$store.commit("setUserData", null, { root: true })
          this.$store.commit("setUserToken", {}, { root: true })
          localStorage.removeItem("userToken")
          this.$router.push({ name: "Home", query: { is_force: 1 } });
        }
          break
        default:
          break
      }

      this.records_loading = !this.records_loading
    },

    format_date(date) {
      return formatDate(date)
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    close_dialog() {
      this.$emit("set_request_history_dialog")
    }
  }
}
</script>