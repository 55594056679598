<template>
  <v-dialog
    persistent
    scrollable
    :fullscreen="!$vuetify.breakpoint.mdAndUp"
    v-model="dialog"
    max-width="900"
  >
    <v-card>
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-regular pa-2">
        <span>Request</span>

        <v-btn icon class="px-2" @click="close_dialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-card-text class="d-flex flex-column pa-2" style="gap: 1rem">
        <v-row>
          <v-col cols="12" md="6">
            <v-form
              class="d-flex flex-column"
              style="gap: 0.6rem"
              :disabled="records_loading"
            >
              <span>Filter by Transaction Date</span>

              <v-menu
                v-model="date_menu"
                :close-on-content-click="false"
                transition="slide-x-transition"
                bottom
                right
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_from"
                    label="Date From"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @keyup="date_menu = true"
                    @keydown="date_menu = false"
                    clearable
                    hide-details="auto"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_from"
                  @input="date_menu = false"
                ></v-date-picker>
              </v-menu>

              <v-menu
                v-model="date_menu2"
                :close-on-content-click="false"
                transition="slide-x-transition"
                bottom
                right
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_to"
                    label="Date To"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @keyup="date_menu2 = true"
                    @keydown="date_menu2 = false"
                    clearable
                    hide-details="auto"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_to"
                  @input="date_menu2 = false"
                ></v-date-picker>
              </v-menu>

              <div class="d-flex justify-end" style="gap: 0.3rem">
                <v-btn
                  text
                  depressed
                  class="px-2"
                  color="grey"
                  :disabled="records_loading"
                  @click="close_dialog"
                >
                  Cancel
                </v-btn>

                <v-btn
                  depressed
                  class="px-2"
                  color="primary"
                  :disabled="records_loading || (!date_from && !date_to)"
                  :loading="records_loading"
                  @click="doGetForSettlementCommission"
                >
                  Filter
                </v-btn>
              </div>
            </v-form>
          </v-col>

          <v-col cols="12" md="6" class="d-flex justify-center">
            <div
              class="d-flex flex-column justify-center align-center pa-4"
              style="gap: 0.75rem"
            >
              <span class="text-overline">TOTAL COMMISSION</span>
              <v-divider style="width: 100%"></v-divider>
              <span>{{ money_format(parseFloat(total_commission)) }}</span>
            </div>
          </v-col>
        </v-row>

        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="records"
          item-key="created_at"
          show-select
          :single-select="false"
          :items-per-page="-1"
          :hide-default-footer="true"
          :loading="records_loading"
        >
          <template v-slot:[`item.bets`]="{ item }">
            {{ number_format(parseInt(item.bets)) }}
          </template>

          <template v-slot:[`item.bet_amount`]="{ item }">
            {{ money_format(parseFloat(item.bet_amount)) }}
          </template>

          <template v-slot:[`item.percentage`]="{ item }">
            {{ `${money_format(parseFloat(item.percentage))}%` }}
          </template>

          <template v-slot:[`item.commission`]="{ item }">
            {{ money_format(parseFloat(item.commission)) }}
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            {{ format_date(item.created_at) }}
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <div class="d-flex justify-end" style="width: 100%; gap: 0.3rem">
          <v-btn depressed text color="grey" class="px-2" @click="close_dialog"
            >Cancel</v-btn
          >
          <v-btn
            depressed
            color="primary"
            class="px-2"
            @click="set_confirmation_dialog"
            :disabled="!selected.length"
            >Request</v-btn
          >
        </div>
      </v-card-actions>
    </v-card>

    <RequestConfirmation
      v-if="confirmation_dialog"
      :selected_data="confirmation_data"
      @set_close_confirmation_dialog="set_close_confirmation_dialog"
    />
  </v-dialog>
</template>

<script>
import RequestConfirmation from "@/components/app/my_commission/RequestConfirmation";
import responseGet from "@/helpers/api_request_get";
import formatDate from "@/helpers/formatDate";

export default {
  components: { RequestConfirmation },
  data: () => ({
    records: [],
    records_loading: false,
    date_from: null,
    date_to: null,
    date_menu: false,
    date_menu2: false,
    selected: [],
    headers: [
      
      {
        text: "Transaction",
        align: "right",
        value: "bets",
        class: "text-uppercase",
      },
      {
        text: "Transaction date",
        align: "start",
        value: "created_at",
        class: "text-uppercase",
      },
      {
        text: "Token Amount",
        align: "right",
        value: "bet_amount",
        class: "text-uppercase",
      },
      {
        text: "Commission %",
        align: "right",
        value: "percentage",
        class: "text-uppercase",
      },
      {
        text: "Commission",
        align: "right",
        value: "commission",
        class: "text-uppercase",
      },
    ],
    confirmation_dialog: false,
    confirmation_data: [],
    request_status: false,
  }),

  computed: {
    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.close_dialog();
      },
    },

    total_commission() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseFloat(obj.commission) + total, 0);
      } else {
        return 0;
      }
    },
  },

  mounted() {
    this.doGetForSettlementCommission();
  },

  methods: {
    async doGetForSettlementCommission() {
      this.records_loading = !this.records_loading;

      const response = await responseGet(this.set_form_data(), "commissions");

      switch (response.status) {
        case 200:
          {
            this.records = response.data.records;
          }
          break;
        case 401:
          {
            this.$store.commit("resetStates", "", { root: true });
            this.$store.commit("setUserData", null, { root: true });
            this.$store.commit("setUserToken", {}, { root: true });
            localStorage.removeItem("userToken");
            this.$router.push({ name: "Home", query: { is_force: 1 } });
          }
          break;
      }

      this.records_loading = !this.records_loading;
    },

    set_form_data() {
      const form_data = new FormData();

      form_data.append("date_from", this.date_from ?? "");
      form_data.append("date_to", this.date_to ?? this.date_from ?? "");

      return new URLSearchParams(form_data);
    },

    set_confirmation_dialog() {
      this.confirmation_data = this.selected;
      this.confirmation_dialog = !this.confirmation_dialog;
    },

    set_close_confirmation_dialog(status) {
      if (status) {
        this.request_status = status;
        this.selected = [];
        this.doGetForSettlementCommission();
      }

      this.confirmation_dialog = !this.confirmation_dialog;
    },

    format_date(date) {
      return formatDate(date, "date");
    },

    number_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    close_dialog() {
      this.$emit("set_close_request_dialog", this.request_status);
    },
  },
};
</script>
