<template>
  <div class="main-content pa-4 pa-sm-6 pa-md-8">
    <v-card class="elevation-10">
      <div class="pa-4 pa-sm-6">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          persistent
          width="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="float-right" icon small v-bind="attrs" v-on="on">
              <v-icon :color="modal ? 'primary' : undefined">mdi-calendar-clock</v-icon>
            </v-btn>
          </template>
          <v-date-picker v-model="date" scrollable>
            <v-spacer></v-spacer>
            <v-btn text @click="modal = false"> Cancel </v-btn>
            <v-btn color="primary" depressed @click="filterDate"> Ok </v-btn>
          </v-date-picker>
        </v-dialog>

        <h3 class="font-weight-bold">My Payouts</h3>
        <div class="grey--text mt-2">Payouts history list</div>
      </div>
      <v-divider></v-divider>

      <v-data-table
        :headers="headers"
        :items="records"
        :items-per-page="itemPerPage"
        :loading="recordsLoading"
        loading-text="Loading, please wait"
      >
        <template v-slot:[`item.amount`]="{ item }">
          <span>{{ moneyFormat(parseFloat(item.amount)) }}</span>
        </template>

        <template v-slot:[`item.requested_at`]="{ item }">
          <span>{{ formatTheDate(item.requested_at) }}</span>
        </template>

        <template v-slot:[`item.approved_at`]="{ item }">
          <span>{{ formatTheDate(item.approved_at) }}</span>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                small
                :disabled="item.status != 'Pending'"
                color="primary"
                @click="confirmRequest(item)"
                class="d-none d-sm-inline"
              >
                <v-icon> mdi-reply-outline </v-icon>
              </v-btn>
            </template>
            <span>Request</span>
          </v-tooltip>

          <v-btn
            outlined
            small
            color="primary"
            @click="confirmRequest(item)"
            class="d-inline d-sm-none"
          >
            Request
          </v-btn>
        </template>
      </v-data-table>
      <!-- <v-card-text>
        <v-row justify="space-between">
          <v-col cols="auto" class="py-1">
            <span class="subtitle-1"
              ><span v-if="!date">Recent Payouts</span> <span v-else>My Payouts</span>
              <span v-if="!recordsLoading"
                >({{ parseInt(records.length).toLocaleString() }})</span
              ></span
            >
          </v-col>
          <v-col cols="auto" class="text-right py-1">
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date"
              persistent
              width="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn small outlined color="primary" v-bind="attrs" v-on="on">
                  <v-icon small class="me-2"> mdi-calendar-text-outline </v-icon>
                  {{ dateFormatted }}
                </v-btn>
              </template>
              <v-date-picker v-model="date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text @click="modal = false"> Cancel </v-btn>
                <v-btn text @click="filterDate"> Ok </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-text> -->
    </v-card>
    <!-- <v-card class="mt-3">
      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :items="records"
          :items-per-page="itemPerPage"
          :loading="recordsLoading"
          loading-text="Loading, please wait"
        >
          <template v-slot:[`item.amount`]="{ item }">
            <span>{{ moneyFormat(parseFloat(item.amount)) }}</span>
          </template>

          <template v-slot:[`item.requested_at`]="{ item }">
            <span>{{ formatTheDate(item.requested_at) }}</span>
          </template>

          <template v-slot:[`item.approved_at`]="{ item }">
            <span>{{ formatTheDate(item.approved_at) }}</span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  :disabled="item.status != 'Pending'"
                  color="primary"
                  @click="confirmRequest(item)"
                  class="d-none d-sm-inline"
                >
                  <v-icon> mdi-reply-outline </v-icon>
                </v-btn>
              </template>
              <span>Request</span>
            </v-tooltip>

            <v-btn
              outlined
              small
              color="primary"
              @click="confirmRequest(item)"
              class="d-inline d-sm-none"
            >
              Request
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card> -->

    <v-dialog v-model="confirmationDialog" persistent width="300">
      <v-card>
        <v-card-title class="text-h6 d-block text-center"> Confirm Request </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-3 px-4 text-center">
          Are you sure you want to request {{ moneyFormat(parseFloat(request_amount)) }},
          please confirm that you would like to proceed.
          <v-row class="mt-3" dense>
            <v-col>
              <v-btn
                ref="yes"
                color="primary"
                :loading="requestLoading"
                depressed
                @click="request()"
                block
              >
                Confirm
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                dark
                color="grey"
                depressed
                @click="confirmationDialog = false"
                block
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbarSuccess"
      top
      color="success"
      :timeout="snackbarSuccessTimeout"
    >
      <v-icon class="me-2"> mdi-check-circle-outline </v-icon>
      Successfully Requested.
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbarSuccess = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="snackbarError" top color="error" :timeout="snackbarErrorTimeout">
      <v-icon class="me-2"> mdi-alert-circle-outline </v-icon>
      {{ requestError }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbarError = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import formatDate from "@/helpers/formatDate";
import { mapState, mapActions } from "vuex";

export default {
  data: () => ({
    date: "",
    modal: false,
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    now: new Date().toISOString(),
    headers: [
      { text: "Name", value: "investor", class: "text-uppercase" },
      { text: "Amount", value: "amount", class: "text-uppercase" },
      { text: "Status", value: "status", class: "text-uppercase" },
      { text: "Date Requested", value: "requested_at", class: "text-uppercase" },
      { text: "Date Approved", value: "approved_at", class: "text-uppercase" },
      { text: "Action", value: "action", sortable: false, class: "text-uppercase" },
    ],
    confirmationDialog: false,
    request_amount: null,
    payout_id: null,
    request_id: null,
    snackbarSuccess: false,
    snackbarSuccessTimeout: -1,
    snackbarError: false,
    snackbarErrorTimeout: -1,
  }),

  created() {
    this.$store.commit("payouts/setRecords", []);
    this.loadRecords();
  },

  computed: {
    ...mapState("payouts", [
      "records",
      "recordsLoading",
      "requestLoading",
      "requestError",
      "requestHasError",
    ]),

    itemPerPage() {
      if (!this.date) {
        return -1;
      } else {
        return 10;
      }
    },

    dateFormatted() {
      if (!this.date) {
        return "Filter";
      } else {
        if (this.date == this.today) {
          return "Today";
        } else {
          return formatDate(this.date, "date");
        }
      }
    },
  },

  methods: {
    ...mapActions("payouts", ["getRecords", "doRequest"]),

    filterDate() {
      this.$refs.dialog.save(this.date);

      this.loadRecords();
    },

    formatTheDate(theDate) {
      return formatDate(theDate);
    },

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    async loadRecords() {
      if (!this.recordsLoading) {
        const formData = new FormData();
        formData.append("date_start", this.date);

        const getData = new URLSearchParams(formData);

        await this.getRecords(getData);
      }
    },

    confirmRequest(item) {
      this.request_amount = item.amount;
      this.payout_id = item.id;
      this.request_id = item.investor_payout_log_id;
      this.confirmationDialog = true;
    },

    async request() {
      this.snackbarSuccessTimeout = -1;
      this.snackbarErrorTimeout = -1;

      if (!this.requestLoading) {
        const formData = new FormData();
        formData.append("payout_id", this.payout_id);
        formData.append("request_id", this.request_id);

        await this.doRequest(formData);

        this.snackbarSuccess = false;
        this.snackbarError = false;

        if (!this.requestHasError) {
          this.snackbarSuccess = true;
          this.snackbarSuccessTimeout = 4000;

          // Update payout
          const thisPayout = this.records.find((record) => record.id == this.payout_id);
          thisPayout.status = "Requested";
          thisPayout.requested_at = this.now;
        } else {
          if (this.requestError) {
            this.snackbarError = true;
            this.snackbarErrorTimeout = 4000;
          }
        }

        this.confirmationDialog = false;
      }
    },
  },
};
</script>
