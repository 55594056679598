<template>
  <div class="main-content pa-4 pa-sm-6 pa-md-8">
    <div class="mb-4">
      <div class="bs-row bs-g-3">
        <div class="bs-col-md-6 bs-col-lg-3">
          <v-card class="elevation-10 bs-h-100">
            <div class="pa-4 pa-sm-6">
              <!-- <v-btn
                depressed
                color="primary"
                height="30"
                class="px-2"
                :disabled="recordsLoading || parseFloat(unpaidCommission) == 0"
                @click="request_dialog = !request_dialog"
                >Request</v-btn
              >

              <v-btn
                depressed
                outlined
                color="primary"
                height="30"
                class="px-2"
                :disabled="recordsLoading"
                @click="set_request_history_dialog"
                >History</v-btn
              > -->
              <v-menu offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small class="light float-right" v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item link @click="request_dialog = !request_dialog">
                    <v-list-item-title>Request</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="setRequestHistoryDialog">
                    <v-list-item-title>History</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <div class="grey--text mb-4 mb-sm-6">Unpaid Commissions</div>
              <h1 class="d-flex" style="line-height: 0.8">
                {{ unpaidCommission }}
              </h1>
            </div>
          </v-card>
        </div>
        <div class="bs-col-6 bs-col-lg-3">
          <v-card class="elevation-10 bs-h-100">
            <div class="pa-4 pa-sm-6">
              <!-- <v-avatar
                class="rounded-lg float-end d-none d-sm-flex float-right"
                size="45"
                color="light"
                tile
              >
                <v-icon color="primary">mdi-hand-coin-outline</v-icon>
              </v-avatar> -->
              <img
                width="48"
                height="48"
                class="d-inline-block float-end"
                src="https://img.icons8.com/3d-fluency/94/receive-cash.png"
                alt="receive-cash"
              />
              <div class="grey--text mb-4 mb-sm-6">Total Bets</div>
              <h1 style="line-height: 0.8">{{ parseInt(totalBets).toLocaleString() }}</h1>
            </div>
          </v-card>
        </div>
        <div class="bs-col-6 bs-col-lg-3">
          <v-card class="elevation-10 bs-h-100">
            <div class="pa-4 pa-sm-6">
              <!-- <v-avatar
                class="rounded-lg float-end d-none d-sm-flex float-right"
                size="45"
                color="light"
                tile
              >
                <v-icon color="red">mdi-hand-coin-outline</v-icon>
              </v-avatar> -->
              <img
                width="48"
                height="48"
                class="d-inline-block float-end"
                src="https://img.icons8.com/3d-fluency/94/coins.png"
                alt="coins"
              />
              <div class="grey--text mb-4 mb-sm-6">Total Token Amount</div>
              <h1 style="line-height: 0.8">
                {{ parseInt(totalBetAmount).toLocaleString() }}
              </h1>
            </div>
          </v-card>
        </div>
        <div class="bs-col-md-6 bs-col-lg-3">
          <v-card class="elevation-10 bs-h-100">
            <div class="pa-4 pa-sm-6">
              <!-- <v-avatar
                class="rounded-lg float-end d-none d-sm-flex float-right"
                size="45"
                color="light"
                tile
              >
                <v-icon color="green">mdi-hand-coin-outline</v-icon>
              </v-avatar> -->
              <img
                width="48"
                height="48"
                class="d-inline-block float-end"
                src="https://img.icons8.com/3d-fluency/94/money-box.png"
                alt="money-box"
              />
              <div class="grey--text mb-4 mb-sm-6">Total Commission</div>
              <h1 style="line-height: 0.8">
                {{ totalCommission ? moneyFormat2(parseFloat(totalCommission)) : "-" }}
              </h1>
            </div>
          </v-card>
        </div>
      </div>
    </div>

    <v-card class="elevation-10">
      <div class="pa-4 pa-sm-6">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          persistent
          width="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small class="light float-right" v-bind="attrs" v-on="on">
              <v-icon :color="modal ? 'primary' : undefined"
                >mdi-calendar-text-outline</v-icon
              >
            </v-btn>
          </template>
          <v-date-picker v-model="date" scrollable>
            <v-spacer></v-spacer>
            <v-btn text @click="modal = false"> Cancel </v-btn>
            <v-btn depressed color="primary" @click="filterDate"> Ok </v-btn>
          </v-date-picker>
        </v-dialog>
        <h3 class="font-weight-bold">My Commissions</h3>
        <div class="grey--text mt-2">Commission history list</div>
      </div>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :items="records"
          :items-per-page="5"
          :loading="recordsLoading"
          loading-text="Loading, please wait"
        >
          <template v-slot:[`item.game`]="{ item }">
            <div class="d-flex align-center bs-gap-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-img
                    :src="item.team_a_logo"
                    max-width="24"
                    max-height="24"
                    v-bind="attrs"
                    v-on="on"
                  ></v-img>
                </template>
                <span>{{ item.team_a_alias }}</span>
              </v-tooltip>

              <span>VS</span>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-img
                    :src="item.team_b_logo"
                    max-width="24"
                    max-height="24"
                    v-bind="attrs"
                    v-on="on"
                  ></v-img>
                </template>
                <span>{{ item.team_b_alias }}</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:[`item.bet_amount`]="{ item }">
            <span>{{ moneyFormat(parseFloat(item.bet_amount)) }}</span>
          </template>

          <template v-slot:[`item.commission`]="{ item }">
            <span v-if="item.commission">
              {{ item.commission }}
            </span>
            <span v-else>-</span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  @click="viewDetails(item)"
                  class="d-none d-sm-inline"
                >
                  <v-icon> mdi-card-search-outline </v-icon>
                </v-btn>
              </template>
              <span>View Details</span>
            </v-tooltip>

            <v-btn
              outlined
              small
              color="primary"
              @click="viewDetails(item)"
              class="d-inline d-sm-none"
            >
              View Details
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" scrollable max-width="500">
      <v-card>
        <v-btn icon @click="dialog = false" absolute right style="top: 10px; right: 5px">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="py-3 px-4"> Commission Details </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-4 px-3">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Token Amount</th>
                  <th class="text-center">Commission Percent</th>
                  <th class="text-center">Commission Amount</th>
                  <th>Paid</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in viewData.commissions" :key="item.commission_id">
                  <td class="text-center">{{ item.bet_amount }}</td>
                  <td class="text-center">{{ moneyFormat2(item.commission_percent) }}</td>
                  <td class="text-right">{{ moneyFormat2(item.commission_amount) }}</td>
                  <td>{{ item.is_paid }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <Request v-if="request_dialog" @set_close_request_dialog="set_close_request_dialog" />
    <RequestHistory
      v-if="request_history_dialog"
      @setRequestHistoryDialog="setRequestHistoryDialog"
    />
  </div>
</template>

<script>
import formatDate from "@/helpers/formatDate";
import { mapState, mapActions } from "vuex";
import Request from "@/components/app/my_commission/Request";
import RequestHistory from "@/components/app/my_commission/RequestHistory";

export default {
  components: { Request, RequestHistory },

  data: () => ({
    date: "",
    modal: false,
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    headers: [
      { text: "Game Type", sortable: false, value: "game_type", class: "text-uppercase" },
      { text: "Game", sortable: false, value: "game", class: "text-uppercase" },
      {
        text: "Transaction Count",
        value: "bet_count",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Token Amount",
        value: "bet_amount",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Commission",
        value: "commission",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        class: "text-uppercase",
        align: "right",
      },
    ],
    viewData: null,
    dialog: false,
    request_dialog: false,
    request_history_dialog: false,
  }),

  created() {
    this.$store.commit("my_commissions/setRecords", []);
    this.loadRecords();
  },

  computed: {
    ...mapState("my_commissions", ["records", "recordsLoading", "unpaidCommission"]),

    dateFormatted() {
      if (!this.date) {
        return "Filter";
      } else {
        if (this.date == this.today) {
          return "Today";
        } else {
          return formatDate(this.date, "date");
        }
      }
    },

    totalBets() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseInt(obj.bet_count) + total, 0);
      } else {
        return 0;
      }
    },

    totalBetAmount() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseFloat(obj.bet_amount) + total, 0);
      } else {
        return 0;
      }
    },

    totalCommission() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseFloat(obj.commission) + total, 0);
      } else {
        return 0;
      }
    },
  },

  methods: {
    ...mapActions("my_commissions", ["getRecords"]),

    filterDate() {
      this.$refs.dialog.save(this.date);

      this.loadRecords();
    },

    async loadRecords() {
      if (!this.recordsLoading) {
        const formData = new FormData();
        formData.append("date_start", this.date);

        const getData = new URLSearchParams(formData);

        await this.getRecords(getData);
      }
    },

    set_close_request_dialog(status) {
      if (status) {
        this.$store.commit("my_commissions/setRecords", []);
        this.loadRecords();
      }

      this.request_dialog = !this.request_dialog;
    },

    setRequestHistoryDialog() {
      this.request_history_dialog = !this.request_history_dialog;
    },

    viewDetails(item) {
      this.viewData = item;
      this.dialog = true;
    },

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    moneyFormat2(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>
