<template>
  <v-dialog scrollable persistent v-model="dialog" max-width="800">
    <v-card class="bs-shadow">
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-regular pa-2">
        Confirm

        <v-btn
          icon
          @click="$emit('set_close_confirmation_dialog', false)"
          :disabled="confirmation_loading || confirmation_status"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-card-text class="d-flex flex-column pa-2" style="gap: 0.6rem">
        <v-alert
          v-if="alert_dialog"
          text
          :color="confirmation_status ? 'success' : 'error'"
          class="mb-0"
        >
          {{ alert_message }}
        </v-alert>

        <v-data-table
          :headers="headers"
          :items="selected_data"
          item-key="name"
          :items-per-page="5"
        >
          <template v-slot:[`item.bets`]="{ item }">
            {{ number_format(parseInt(item.bets)) }}
          </template>

          <template v-slot:[`item.bet_amount`]="{ item }">
            {{ money_format(parseFloat(item.bet_amount)) }}
          </template>

          <template v-slot:[`item.percentage`]="{ item }">
            {{ `${money_format(parseFloat(item.percentage))}%` }}
          </template>

          <template v-slot:[`item.commission`]="{ item }">
            {{ money_format(parseFloat(item.commission)) }}
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            {{ format_date(item.created_at) }}
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <div class="d-flex justify-space-between align-end" style="width: 100%">
          <span>{{
            `Total commission of ${money_format(parseFloat(total_commission))}`
          }}</span>

          <div class="d-flex justify-end" style="gap: 0.3rem">
            <v-btn
              depressed
              text
              color="grey"
              class="px-2"
              :disabled="confirmation_loading"
              @click="$emit('set_close_confirmation_dialog', false)"
            >
              Cancel
            </v-btn>

            <v-btn
              depressed
              color="primary"
              class="px-2"
              :loading="confirmation_loading"
              :disabled="confirmation_status"
              @click="doConfirm"
            >
              Confirm
            </v-btn>
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import responsePost from "@/helpers/api_request_post";
import formatDate from "@/helpers/formatDate";

export default {
  props: {
    selected_data: {
      type: Array,
    },
  },

  data: () => ({
    compiled_dates: [],
    compiled_total_commission: 0,
    headers: [
      
      {
        text: "Transaction",
        align: "right",
        value: "bets",
        class: "text-uppercase",
      },
      {
        text: "Transaction Date",
        align: "start",
        value: "created_at",
        class: "text-uppercase",
      },
      {
        text: "Token Amount",
        align: "right",
        value: "bet_amount",
        class: "text-uppercase",
      },
      {
        text: "Commission %",
        align: "right",
        value: "percentage",
        class: "text-uppercase",
      },
      {
        text: "Commission",
        align: "right",
        value: "commission",
        class: "text-uppercase",
      },
    ],
    confirmation_status: false,
    confirmation_loading: false,
    alert_dialog: false,
    alert_message: "",
  }),

  computed: {
    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.close_confirm_dialog();
      },
    },

    total_commission() {
      if (this.selected_data) {
        return this.selected_data.reduce(
          (total, obj) => parseFloat(obj.commission) + total,
          0
        );
      }
      return 0;
    },
  },

  mounted() {
    this.get_selected_dates();
  },

  methods: {
    async doConfirm() {
      this.confirmation_loading = !this.confirmation_loading;

      const response = await responsePost(this.set_form_data(), "commission/confirm");
      this.alert_dialog = true;

      switch (response.status) {
        case 200:
          {
            this.confirmation_status = true;
            this.alert_message = "Successfully requested.";

            setTimeout(() => {
              this.$emit("set_close_confirmation_dialog", true);
            }, 5000);
          }
          break;
        case 401:
          {
            this.$store.commit("resetStates", "", { root: true });
            this.$store.commit("setUserData", null, { root: true });
            this.$store.commit("setUserToken", {}, { root: true });
            localStorage.removeItem("userToken");
            this.$router.push({ name: "Home", query: { is_force: 1 } });
          }
          break;
        default:
          this.alert_message = "Something went wrong.";
          break;
      }

      this.confirmation_loading = !this.confirmation_loading;
    },

    set_form_data() {
      const form_data = new FormData();

      form_data.append("dates", JSON.stringify(this.compiled_dates));
      form_data.append("total_amount", this.get_total_commission());

      return form_data;
    },

    get_selected_dates() {
      this.selected_data.forEach((element) => {
        this.compiled_dates.push(element.formatted_created_at);
      });
    },

    get_total_commission() {
      if (this.selected_data) {
        return this.selected_data.reduce(
          (total, obj) => parseFloat(obj.commission) + total,
          0
        );
      }
      return 0;
    },

    format_date(date) {
      return formatDate(date, "date");
    },

    number_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>
